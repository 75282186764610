"use client";
import React, { FC, createRef, useEffect, useState } from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import { track } from "@vercel/analytics";
import { MdClose as CloseIcon } from "react-icons/md";

import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";

import { localizedPaths } from "i18n/utils";
import { locales } from "navigation";
import Hamburger from "public/hamburger.svg";

import { allMenuItemsType, countriesType, paths } from "lib/types";
import { useCurrentLocale, useScrollTrigger } from "lib/utils";

import AnimatedLink from "./animated-link";
import Container from "./container";
import { CountrySelector } from "./country-selector";
import DarkModeSelectorLoader from "./dark-mode-loader";
import { DarkModeSelectorProps } from "./dark-mode-selector";

const DarkModeSelector = dynamic(() => import("./dark-mode-selector"), {
  // eslint-disable-next-line react/no-unstable-nested-components
  loading: () => <DarkModeSelectorLoader />,
});

interface Translations {
  close: string;
  countryNav: string;
  mainMenu: string;
  menu: string;
  youAreHere: string;
}
interface MobileMenuProps {
  /**
   * All menu items.
   */
  allMenuItems: allMenuItemsType;
  darkModeTranslations: DarkModeSelectorProps["translations"];
  translations: Translations;
}

export const MobileMenu: FC<MobileMenuProps> = ({
  allMenuItems,
  darkModeTranslations,
  translations,
}) => {
  const t = translations;
  const locale = useCurrentLocale();
  const isScrolled = useScrollTrigger(24);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const pathname = usePathname();

  const isCurrentPage = (target: keyof paths) => {
    if (target === "home") {
      return ["/", "/en/", "/no/"].includes(pathname);
    }
    return pathname.includes(localizedPaths(locale)[target]);
  };

  const allMobileMenuItems: allMenuItemsType = allMenuItems && [
    { title: "Start", target: "/" },
    ...allMenuItems,
  ];
  const localesLength = locales.length ? locales.length : 1;

  const gridClassName = {
    1: "grid-cols-1",
    2: "grid-cols-2",
    3: "grid-cols-3",
  }[localesLength];
  const dialogRef = createRef<HTMLDialogElement>();
  const menuButtonRef = createRef<HTMLButtonElement>();
  const [isMounted, setIsMounted] = useState(false);
  // Treat the first locale in the ENV as the default locale
  const defaultLocale = process.env.SITE_LOCALES?.split(",")[0];

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleOpenMenu = () => {
    if (dialogRef.current) dialogRef.current.showModal();
    track("Mobile menu", { value: mobileMenuOpen ? "close" : "open" });
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleCloseMenu = () => {
    if (dialogRef.current) dialogRef.current.close();
    if (menuButtonRef.current) menuButtonRef.current.focus();
    setMobileMenuOpen(false);
  };

  const MobileMenuInner = (
    <div className="flex flex-row justify-end gap-[16px] lg:hidden">
      {isMounted ? (
        <DarkModeSelector translations={darkModeTranslations} />
      ) : (
        <DarkModeSelectorLoader />
      )}
      <button
        aria-expanded={mobileMenuOpen}
        className="flex h-12 items-center rounded-full bg-beige px-4 py-2 text-lg font-semibold transition-background hover:brightness-90 focus-visible:brightness-95 dark:bg-slate-800"
        onClick={handleOpenMenu}
        style={{
          fontSize: "clamp(16px, 1.125rem, 24px)",
        }}
        type="button"
      >
        <Hamburger className="mr-2" height="1em" width="1em" />
        <span>{t.menu}</span>
      </button>
    </div>
  );

  const cleanUpPath = (path: string): keyof typeof localizedPaths => {
    if (path === "/") return "home" as keyof typeof localizedPaths;
    return path.replace("/", "") as keyof typeof localizedPaths;
  };

  return (
    <>
      <div
        className={`mobile-menu-bar transition-colors ${
          isScrolled
            ? `fixed right-0 top-0 z-[1100] w-full border-b-2 py-2 dark:border-b-slate-800 dark:bg-slate-900 `
            : ""
        } inline lg:hidden`}
      >
        {isScrolled ? (
          <Container>{MobileMenuInner}</Container>
        ) : (
          MobileMenuInner
        )}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <dialog
        className={`${
          mobileMenuOpen ? "flex opacity-100" : "opacity-0"
        } mobile-menu my-0 ml-auto mr-0 box-content h-screen w-[calc(100vw_-_16px)] border-l-[1px] border-beige py-8 backdrop:bg-[rgba(0,0,0,0.3)] reduce-motion:duration-0 dark:border-l-2 dark:border-slate-700 dark:bg-slate-900 dark:text-white ${
          isScrolled ? "pt-2" : ""
        }`}
        onClick={(event) => {
          if (dialogRef.current && event.target === dialogRef.current) {
            handleCloseMenu();
          }
        }}
        ref={dialogRef}
      >
        <div
          className={`w-full overflow-scroll text-xl font-semibold transition-width dark:bg-slate-900 dark:text-white  `}
        >
          <Container className="mx-0 flex flex-col">
            <button
              className="flex h-12 w-fit min-w-[108px] items-center self-end rounded-full bg-beige px-4 py-2 text-lg font-semibold transition-background hover:brightness-90 focus-visible:brightness-95 dark:bg-slate-800"
              onClick={handleCloseMenu}
              style={{
                fontSize: "clamp(16px, 1.125rem, 24px)",
              }}
              type="button"
            >
              <CloseIcon
                className="ml-[-4px] mr-2 inline-flex text-2xl"
                height="1em"
                width="1em"
              />
              {t.close}
            </button>
            <nav aria-label={t.mainMenu} className="flex flex-col">
              {allMobileMenuItems?.map((item) => {
                const path = cleanUpPath(item.target);
                return (
                  <AnimatedLink
                    aria-current={
                      isCurrentPage(path as keyof paths) ? "page" : undefined
                    }
                    className="p-4 text-center text-2xl text-[clamp(24px,_1.5rem,_32px)] underline-offset-[0.25em] focus-visible:brightness-95 aria-current:underline"
                    href={`/${
                      locale !== defaultLocale
                        ? `${locale}/${localizedPaths(locale)[path]}`
                        : `${localizedPaths(locale)[path]}`
                    }`}
                    key={item.title}
                    onClick={handleCloseMenu}
                  >
                    {item.title}
                  </AnimatedLink>
                );
              })}
            </nav>
            <nav
              aria-label={t.countryNav}
              className={`mt-8 self-center ${gridClassName}`}
            >
              <CountrySelector
                activeLocale={locale}
                className="flex flex-wrap gap-4 [&>li]:mx-auto"
                countries={locales as unknown as Array<countriesType>}
                locale={locale}
                translations={{
                  youAreHere: t.youAreHere,
                }}
              />
            </nav>
          </Container>
        </div>
      </dialog>
    </>
  );
};
export default MobileMenu;
