"use client";
import React, { forwardRef } from "react";

import Link, { LinkProps } from "next/link";
import { usePathname } from "next/navigation";

import useAnimatedRouter from "lib/viewTransitions";

type Props = {
  children: React.ReactNode;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  href: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & LinkProps;

const AnimatedLink = forwardRef<HTMLAnchorElement, Props>(
  ({ children, href, ...other }, ref) => {
    const { animatedRoute } = useAnimatedRouter();
    const pathname = usePathname();
    const isCurrentPage = (target: string) => {
      return pathname.replaceAll("/", "").includes(target.replaceAll("/", ""));
    };

    return (
      <Link
        aria-current={isCurrentPage(href) ? "page" : undefined}
        href={href}
        onClick={(event) => {
          // Do not animate if the user is using the keyboard to navigate
          if (event.ctrlKey || event.metaKey) {
            return;
          }
          animatedRoute(href);
        }}
        passHref
        ref={ref}
        {...other}
      >
        {children}
      </Link>
    );
  },
);
export default AnimatedLink;
