import React, { useState, useEffect, FC } from "react";

import dynamic from "next/dynamic";

import { PopperProps } from "@mui/base/Popper";

import { poppins } from "lib/utils";

const Popper = dynamic(() => import("@mui/base").then((mod) => mod.Popper));

interface TooltipProps {
  /**
   * The anchor element for the tooltip.
   */
  anchorEl: PopperProps["anchorEl"];
  /**
   * The title of the tooltip.
   */
  title: string;
}
interface TooltipProps {
  anchorEl: PopperProps["anchorEl"];
  title: string;
}
export const Tooltip: FC<TooltipProps> = ({ anchorEl, title }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isTouchscreen, setIsTouchscreen] = useState(false);

  const handleEsc = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setIsOpened(false);
    }
  };

  useEffect(() => {
    setIsTouchscreen(
      window.matchMedia("(hover: none)").matches ||
        window.matchMedia("(pointer: coarse)").matches,
    );
    if (isOpened) {
      // close the tooltip on ESC
      document.addEventListener("keydown", handleEsc);
    }
  }, [isOpened]);

  useEffect(() => {
    if (anchorEl && anchorEl instanceof HTMLElement) {
      anchorEl?.addEventListener("mouseenter", () => setIsOpened(true));
      anchorEl?.addEventListener("mouseleave", () => setIsOpened(false));
      anchorEl?.addEventListener("focus", () => setIsOpened(true));
      anchorEl?.addEventListener("blur", () => setIsOpened(false));
    }
  }, [anchorEl]);

  return isTouchscreen || !isOpened ? null : (
    <Popper
      anchorEl={anchorEl}
      className={`z-[1300] ${poppins.variable} font-sans`}
      open={isOpened}
    >
      <div
        className={`relative mt-[14px] max-w-[300px] rounded-[4px] bg-[#515557] px-2 py-1 text-sm font-normal text-white transition-opacity delay-200 ${
          isOpened ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="absolute left-[50%] top-0 z-[-1] h-[1em] w-[1em] translate-x-[-50%] translate-y-[50%] rotate-45 bg-[#515557]" />
        {title}
      </div>
    </Popper>
  );
};

export default Tooltip;
