"use client";

import React, { FC } from "react";

import { ThemeProvider as NextThemeProvider } from "next-themes";

interface ThemeProviderProps {
  children: React.ReactNode;
}
const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => (
  <NextThemeProvider attribute="class" enableSystem>
    {children}
  </NextThemeProvider>
);

export default ThemeProvider;
