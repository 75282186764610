import React, { forwardRef } from "react";

import { ThemeColorSwitcher } from "./theme-color-switcher";

interface ContainerProps {
  children: React.ReactNode;
  className?: string;
  component?: React.ElementType;
  style?: React.CSSProperties;
  withPadding?: boolean;
}
const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, className, component, style, withPadding: withMargin }, ref) => {
    const Tag = component || "div";

    return (
      <Tag
        className={`mx-auto px-2 2xl:container sm:px-4 md:px-5 ${
          withMargin ? "py-8" : ""
        } ${className || ""}`}
        ref={ref}
        style={style}
      >
        {/* Temp: switch favicon based on theme */}
        <ThemeColorSwitcher />
        {children}
      </Tag>
    );
  },
);

export default Container;
