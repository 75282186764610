"use client";

import { useRouter } from "next/navigation";

export interface ExtendedDocument extends Document {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  startViewTransition: any;
}

export default function useAnimatedRouter() {
  const router = useRouter();

  const viewTransitionsStatus = () => {
    const extendedDocument = document as ExtendedDocument;
    let status = "Opss, Your browser doesn't support View Transitions API";

    if (extendedDocument?.startViewTransition) {
      status = "Yess, Your browser support View Transitions API";
    }

    return status;
  };

  const animatedRoute = (url: string) => {
    const extendedDocument = document as ExtendedDocument;

    if (!extendedDocument?.startViewTransition) {
      return router.push(url, { scroll: false });
    }
    extendedDocument.startViewTransition(() => {
      router.prefetch(url);
      return router.push(url, { scroll: false });
    });
  };

  return { animatedRoute, viewTransitionsStatus };
}
