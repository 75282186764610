"use client";
import { useEffect } from "react";

import { usePreferredTheme } from "lib/utils";

interface ThemeColorSwitcherProps {}

export const ThemeColorSwitcher = ({}: ThemeColorSwitcherProps) => {
  const { preferredTheme } = usePreferredTheme();
  useEffect(() => {
    /* // Define default colors
    const darkColor = "#111827";
    let lightColor = "#7ca7ba";

    // Function to determine light color based on present classes using :has
    const determineLightColor = () => {
      if (document.querySelector(":has(.green-body)")) {
        return "#a9d9b3"; // updated to green from tailwind.config.ts
      } else if (document.querySelector(":has(.red-body)")) {
        return "#f7a7a4"; // updated to red from tailwind.config.ts
      } else if (document.querySelector(":has(.yellow-body)")) {
        return "#f0c868"; // updated to yellow from tailwind.config.ts
      } else if (document.querySelector(":has(.blue-body)")) {
        return "#7ca7ba"; // updated to blue from tailwind.config.ts
      } else if (document.querySelector(":has(.brown-body)")) {
        return "#6E5334"; // updated to brown from tailwind.config.ts
      }
      // Add more conditions as needed for other colors
      return lightColor; // return default if no specific class found
    };

    // Update theme color based on the found class
    lightColor = determineLightColor();

    // Update meta tags for theme-color
    const themeColorMeta = document.querySelectorAll(
      'meta[name="theme-color"]',
    );
    themeColorMeta.forEach((meta) => {
      meta.setAttribute(
        "content",
        preferredTheme === "dark" ? darkColor : lightColor,
      );
    }); */

    // Update favicon based on theme
    const faviconLink = document.querySelector('link[rel="icon"]');
    if (faviconLink) {
      (faviconLink as HTMLLinkElement).href =
        preferredTheme === "dark"
          ? "/favicon/dark/favicon.ico"
          : "/favicon/favicon.ico";
    }
  }, [preferredTheme]);

  return null;
};
