import { FC } from "react";

import { canonicalBaseLanguageURLs } from "i18n/utils";
import DenmarkFlag from "public/dk-flag.svg";
import NorwayFlag from "public/no-flag.svg";
import SwedenFlag from "public/se-flag.svg";

import { ENGLISH } from "lib/constants";
import { countriesType } from "lib/types";

import CountryButtonWithTooltip from "./country-button-with-tooltip";
interface CountrySelectorProps {
  /**
   * The currently active locale. This represents the country that is currently selected.
   */
  activeLocale: countriesType;

  /**
   * The CSS class name applied to the Country Selector component. This is optional.
   */
  className?: string;

  /**
   * An array of country types. Each country type represents a country that can be selected.
   */
  countries: Array<countriesType>;

  /**
   * The default locale. This represents the country that is selected by default when the component is first rendered.
   */
  locale: countriesType;
  translations: {
    youAreHere: string;
  };
}
export const CountrySelector: FC<CountrySelectorProps> = ({
  activeLocale,
  className,
  countries,
  translations,
}) => {
  const t = translations;
  const getLanguageName = (country: countriesType) =>
    ({
      sv: "Useit Sverige",
      no: "Useit Norge",
      en: ENGLISH,
      da: "Useit Danmark",
    })[country];
  const getFlagIcon = (country: countriesType) =>
    ({
      sv: <SwedenFlag height="24" viewBox="2 0 10 10" width="24" />,
      no: <NorwayFlag height="24" viewBox="100 0 800 800" width="24" />,
      en: (
        <span
          aria-hidden="true"
          className="flex h-[48px] w-[48px] items-center justify-center"
        >
          EN
        </span>
      ),
      da: <DenmarkFlag height="24" viewBox="4 2 25 25" width="24" />,
    })[country];

  return countries ? (
    <ul className={`${className} m-0 flex gap-4 p-0`}>
      {countries?.map((country) => (
        <CountryButtonWithTooltip
          ariaCurrent={country === activeLocale ? "page" : undefined}
          ariaLabel={
            country === activeLocale
              ? `${getLanguageName(country)}, ${t.youAreHere}`
              : getLanguageName(country)
          }
          href={canonicalBaseLanguageURLs(country)}
          icon={getFlagIcon(country)}
          key={country}
          lang={country}
        />
      ))}
    </ul>
  ) : null;
};

export default CountrySelector;
