/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";

import dynamic from "next/dynamic";

import { COMPANY_NAME } from "lib/constants";

interface LogoProps extends React.SVGProps<SVGSVGElement> {
  /**
   * ARIA label for the Logo component.
   */
  ariaLabel?: string;

  /**
   * Additional class name for the Logo component.
   */
  className?: string;

  /**
   * The variant of the Logo component.
   * Possible values are: 'pride', 'midsummer', 'regular', 'auto'.
   */
  variant?: "pride" | "midsummer" | "regular" | "auto";
}

interface SVGWrapperProps {
  Component: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  props: React.SVGProps<SVGSVGElement>;
}
const SVGWrapper: FC<SVGWrapperProps> = ({ Component, props }) => (
  <Component {...props} />
);

const DarkLogo = dynamic(() =>
  import("public/useit-dark.svg").then((mod) => ({
    default: (props: any) => (
      <SVGWrapper Component={mod.default} props={props} />
    ),
  })),
);
const DarkMidsummerLogo = dynamic(() =>
  import("public/useit-midsummer-dark.svg").then((mod) => ({
    default: (props: any) => (
      <SVGWrapper Component={mod.default} props={props} />
    ),
  })),
);
const LightMidsummerLogo = dynamic(() =>
  import("public/useit-midsummer.svg").then((mod) => ({
    default: (props: any) => (
      <SVGWrapper Component={mod.default} props={props} />
    ),
  })),
);
const DarkPrideLogo = dynamic(() =>
  import("public/useit-pride-dark.svg").then((mod) => ({
    default: (props: any) => (
      <SVGWrapper Component={mod.default} props={props} />
    ),
  })),
);
const LightPrideLogo = dynamic(() =>
  import("public/useit-pride.svg").then((mod) => ({
    default: (props: any) => (
      <SVGWrapper Component={mod.default} props={props} />
    ),
  })),
);
const LightLogo = dynamic(() =>
  import("public/useit.svg").then((mod) => ({
    default: (props: any) => (
      <SVGWrapper Component={mod.default} props={props} />
    ),
  })),
);

interface LogoProps extends React.SVGProps<SVGSVGElement> {
  ariaLabel?: string;
  className?: string;
  translations: {
    logo: string;
    midsummerLogoAlt: string;
    prideLogoAlt: string;
  };
  variant?: "pride" | "midsummer" | "regular" | "auto";
}

export const Logo: FC<LogoProps> = ({
  ariaLabel,
  className,
  translations,
  variant = "auto",
  ...other
}) => {
  const sharedProps = {
    viewBox: "0 0 351 352",
    width: 125,
    height: 125,
    role: "img",
  };
  const t = translations;

  const darkClasses = "hidden transition-all dark:block";
  const lightClasses = "block transition-all dark:hidden";

  const getLogo = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const date = today.getDate();

    if (
      variant === "midsummer" ||
      (variant === "auto" && month === 6 && date >= 22 && date <= 25)
    ) {
      return (
        <>
          <DarkMidsummerLogo
            {...sharedProps}
            aria-label={ariaLabel || `${COMPANY_NAME} ${t.midsummerLogoAlt}`}
            className={`${darkClasses} ${className}`}
            {...other}
          />
          <LightMidsummerLogo
            {...sharedProps}
            aria-label={ariaLabel || `${COMPANY_NAME} ${t.midsummerLogoAlt}`}
            className={`${lightClasses} ${className}`}
            {...other}
          />
        </>
      );
    }

    if (
      variant === "pride" ||
      (variant === "auto" && month === 6 && date >= 1 && date <= 30)
    ) {
      return (
        <>
          <DarkPrideLogo
            {...sharedProps}
            aria-label={ariaLabel || `${COMPANY_NAME} ${t.prideLogoAlt}`}
            className={`${darkClasses} ${className}`}
            {...other}
          />
          <LightPrideLogo
            {...sharedProps}
            aria-label={ariaLabel || `${COMPANY_NAME} ${t.prideLogoAlt}`}
            className={`${lightClasses} ${className}`}
            {...other}
          />
        </>
      );
    }

    return (
      <>
        <DarkLogo
          {...sharedProps}
          aria-label={ariaLabel || `${COMPANY_NAME} ${t.logo}`}
          className={`${darkClasses} ${className}`}
          {...other}
        />
        <LightLogo
          {...sharedProps}
          aria-label={ariaLabel || `${COMPANY_NAME} ${t.logo}`}
          className={`${lightClasses} ${className}`}
          {...other}
        />
      </>
    );
  };

  return getLogo();
};

export default Logo;
