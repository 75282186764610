"use client";
import { useRef } from "react";

import { usePathname } from "next/navigation";

import { relativeBaseLanguageURLs } from "i18n/utils";

import { COMPANY_NAME } from "lib/constants";
import { useCurrentLocale } from "lib/utils";

import AnimatedLink from "./animated-link";
import Logo from "./logo";
import Tooltip from "./tooltip";

interface LogoWithTooltipProps {
  translations: {
    goToStart: string;
    logo: string;
    midsummerLogoAlt: string;
    prideLogoAlt: string;
  };
}

const LogoWithTooltip: React.FC<LogoWithTooltipProps> = ({ translations }) => {
  const logoRef = useRef<HTMLDivElement>(null);
  const t = translations;
  const isOnStartPage = usePathname() === "/";
  const locale = useCurrentLocale();

  return (
    <>
      <div ref={logoRef}>
        {isOnStartPage ? (
          <Logo translations={translations} />
        ) : (
          <AnimatedLink
            aria-current={isOnStartPage ? "page" : undefined}
            aria-label={`${COMPANY_NAME}, ${t.goToStart}`}
            className="block rounded-lg"
            href={`${relativeBaseLanguageURLs(locale)}`}
          >
            <Logo translations={translations} />
          </AnimatedLink>
        )}
      </div>
      {!isOnStartPage && (
        <Tooltip
          anchorEl={logoRef.current}
          title={`${COMPANY_NAME}, ${t.goToStart}`}
        />
      )}
    </>
  );
};

export default LogoWithTooltip;
